.carousel-absolute{
  position:absolute;
 //left:0;
  top:0;
//  right:0;
  z-index:-1;
  overflow:hidden;
  .owl-carousel-slider-item{
    min-height: 100vh;
  }
}

