.breadcrumb{
  color: #fff;
  margin-bottom: 0;
  li {
    margin-bottom:0;
    a {
      font-size: 13px;
      opacity: .6;
      color: #fff;
    }
  }
}

.breadcrumb>li+li:before{
  color: @brand-color;
}