

.complex-row{
  position:relative;
  clear:both;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.08);

  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;

  .property-row-image{
    width:30%;
    height: auto;
    min-height: 160px;
    position: relative;
    background-color: rgba(0,0,0,.15);
    display: block;
    float: left;
    img{
      display: block;
      position: relative;
      width: 100%;
      z-index: 2;
    }
  }

  .property-row-content{
    width:70%;
    margin:0;
    padding:0 20px;
    float: left;
    min-height: 195px;
    position: relative;

  }
}


.complex-box-content{
  background: @brand-color;
  color: #fff;
  text-align: center;
  h1,h2,h3,h4,h5, p, a{
    color: #fff;
    padding:0;
    margin:0;
  }

  .complex-box-title{
    height: 25px;
    overflow: hidden;
  }

}


@media (max-width: 991px){

  .complex-row{
    .property-row-image{
      width: 100%;
      max-width: 400px;
    }

    .property-row-content{
      width:100%;
    }
  }
}