.imagetext-module{
  height: 380px;
  //padding:45px;
  position: relative;
  margin-bottom: 30px;
}

.imagetext-module-text-wrapper{
  position: absolute;
  bottom:0;
  left:0;
  padding:30px 30px 20px 30px;
  #gradient > .vertical(rgba(0,0,0,0), rgba(0,0,0,.7));
  h1,h2,h3,p{
    color: #fff;
  }

  h1,h2,h3{
    font-size: 30px;
    font-weight: 500;
  }

}

.imagetext-module-link{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  &:hover{
    background: rgba(0,0,0,.3);
  }
}

