
.header-band{
//  background: #EDF1F2;
  padding: 60px 30px 30px 30px;
  position:relative;
  color: #fff;
  #gradient > .vertical(rgba(0,0,0,0), rgba(0,0,0,.7));

  .header-content{
    font-size:22px;
    color: #fff;
  }
  h1{
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    color: #fff;
  }
  .header-band-image{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-size: cover;
    background-position: center center;
    //opacity: .2;
  }
}